import { CalendarEntryGameScrimmage, SnapshotTeamStatsType, TeamId } from "@ollie-sports/models";
import { getServerHelpers } from "../../helpers";

export async function soccerStatSnapshot__server__getSoccerGameEventAndSnapshotData(p: {
  teamIdsWithSquad: string[];
  offset: number;
  pageSize: number;
}): Promise<
  {
    calendarEntryGameScrimmage: CalendarEntryGameScrimmage;
    teamStats: SnapshotTeamStatsType;
    teamNameWithSquad: string;
  }[]
> {
  // SERVER_ONLY_TOGGLE
  const { getAppPgPool } = getServerHelpers();

  if (!p.teamIdsWithSquad.length) {
    return [];
  }

  const query = `
      select
        s.item->'teamStats' as teamStats,
        c.item as calendarEntry,
        t.item as team
      from
        mirror_soccerstatsnapshot s,
        mirror_calendarentry c,
        mirror_team t
          where s.item->>'calendarEntryId' = c.id
          and s.item->>'snapshotType' = 'all'
          and t.id = c.item->>'teamId'
          and s.item->>'teamIdWithSquad' in (${p.teamIdsWithSquad.map(id => `'${id}'`).join(",")})
          order by c.item->'startDateTime' desc
          limit ${p.pageSize}
          offset ${p.offset};`;

  const data: {
    calendarEntryGameScrimmage: CalendarEntryGameScrimmage;
    teamStats: SnapshotTeamStatsType;
    teamNameWithSquad: string;
  }[] = (await getAppPgPool().query(query)).rows.map(row => ({
    teamStats: row.teamstats as SnapshotTeamStatsType,
    calendarEntryGameScrimmage: row.calendarentry as CalendarEntryGameScrimmage,
    teamNameWithSquad: row.team.name as string
  }));

  return data;
  // SERVER_ONLY_TOGGLE
}

soccerStatSnapshot__server__getSoccerGameEventAndSnapshotData.auth = () => {};

// i18n certified - complete
